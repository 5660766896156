import { Box, Container, Typography } from "@mui/material"

export default function EuBanner() {
  return (
    <Box
      component="footer"
      pt={6}
      pb={6}
      sx={{
        a: {
          color: "white",
          textDecoration: "none",
          ":hover": { color: "grey.400" },
        },
      }}
    >
      <Container maxWidth="md">
        {/* eslint-disable-next-line */}
        <img
          src="eu-prague-banner.jpg"
          alt="Evropská unie: Evropské strukturální a investiční fondy OP Praha – pól růstu ČR"
          width="100%"
        />
        <Typography component="div" variant="body2">
          <p>
            V roce 2021 získala firma Pricetag s.r.o. podporu z Evropských
            strukturálních a investičních fondů, realizovanou skrz Operační
            program Praha - pól růstu ČR a čerpanou přes první výzvu
            Specializovaných voucherů (reg.č.
            CZ.07.1.02/0.0/0.0/16_027/0000607). Podpora byla mířena na účast
            firmy na projekt Life Experience Exchange v hl. m. Praze za účelem
            vytvoření webové aplikace a webové stránky projektu Life Experience
            Exchange. Očekávaným výstupem je vytvoření uživatelsky příjemného
            vzhledu aplikace (front-end) a webové stránky projektu.
          </p>
          <p>
            Projekt Life Experience Exchange je spolufinancován Evropskou unií.
          </p>
        </Typography>
      </Container>
    </Box>
  )
}
