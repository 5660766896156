import { useState, useEffect } from "react"

export function useScroll() {
  const [lastScrollTop, setLastScrollTop] = useState(0)
  const [bodyOffset, setBodyOffset] = useState<DOMRect | null>(
    process.browser ? document.body.getBoundingClientRect() : null
  )
  const [scrollY, setScrollY] = useState(bodyOffset?.top ?? 0)
  const [scrollX, setScrollX] = useState(bodyOffset?.left ?? 0)
  const [scrollDirection, setScrollDirection] = useState<
    "up" | "down" | "initial"
  >("initial")

  function listener() {
    setBodyOffset(document.body.getBoundingClientRect())
    setScrollY(bodyOffset ? -bodyOffset.top : 0)
    setScrollX(bodyOffset?.left ?? 0)
    setScrollDirection(scrollY > lastScrollTop ? "down" : "up")
    setLastScrollTop(scrollY)
  }

  useEffect(() => {
    window.addEventListener("scroll", listener)
    return () => {
      window.removeEventListener("scroll", listener)
    }
  })

  return {
    scrollY,
    scrollX,
    scrollDirection,
  }
}
