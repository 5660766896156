import React from "react"
import {
  // Autocomplete,
  Avatar,
  Box,
  Button,
  ButtonBase,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import {
  ExploreTwoTone as ExploreIcon,
  SyncTwoTone as SyncIcon,
  ThumbUpTwoTone as ThumbUpIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material"
import { InlineDivider } from "@/ui/inline-divider"
import { posts } from "@/domain/post/mocks"
import { NextLinkComposed } from "@/lib/routing"
import EuBanner from "@/views/hp/components/eu-banner"
import { HomepageLayout } from "./_layout"

function Hero({ onContinue }: { onContinue(): void }) {
  const minHeight = "calc(100vh - 3rem)"
  return (
    <Box
      component="section"
      bgcolor="primary.main"
      sx={{
        minHeight,
        backgroundImage: `url(/background.svg)`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Container
        sx={{
          minHeight,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={4} pt={6} pb={8} my="auto">
          <Grid item md={6}>
            <Typography
              component="h1"
              variant="h1"
              fontWeight={700}
              color="primary.contrastText"
              lineHeight={1.1}
            >
              life experience
              <br />
              <Box component="span" sx={{ color: "secondary.main" }}>
                exchange
              </Box>
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Stack spacing={4} justifyContent="center" height="100%">
              <Typography
                fontSize={20}
                fontWeight={500}
                color="primary.contrastText"
              >
                <Box component="strong" color="secondary.main">
                  Life Experience Exchange
                </Box>{" "}
                je unikátní program určený pro korporace a jejich zaměstnance,
                který umožňuje výměnu zkušeností a pracovních pozic napříč
                korporátními společnostmi, a to výměnou zaměstnanců stejné
                kvalifikace na časově omezenou dobu.
              </Typography>
              <Box>
                <Button
                  variant="contained"
                  color="secondary"
                  size="xlarge"
                  component={NextLinkComposed}
                  to="/signup"
                >
                  Chci se zapojit
                  {/* alternativy: chci změnu, chci se přidat, chci se zaregistrovat, procházet nabídky */}
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
        <ButtonBase
          onClick={() => onContinue()}
          sx={{
            alignSelf: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "6rem",
            height: "6rem",
            bgcolor: "secondary.main",
            borderRadius: 10000,
            flexShrink: 0,
            mb: "-3rem",
            transition: "transform 100ms",
            "&:hover": {
              transform: "scale(1.1)",
            },
          }}
        >
          <ExpandMoreIcon sx={{ fontSize: 60, color: "primary.main" }} />
        </ButtonBase>
      </Container>
    </Box>
  )
}

function AddedValues({ sectionRef }: { sectionRef: React.Ref<HTMLElement> }) {
  const values = [
    {
      icon: <ExploreIcon sx={{ fontSize: 80 }} />,
      title: "Výměna zkušeností",
      text: "Zaměstnání se časem stáva stereotypní a zároveň změna zaměstnání je těžké rozhodnutí. Program umožňuje výměnu zaměstnanců na stejných pozicích bez nutnosti ukončení pracovního poměru.",
    },
    {
      icon: <ThumbUpIcon sx={{ fontSize: 80 }} />,
      title: "Práce na zkoušku",
      text: "Objevili jste zajímavou pracovní nabídku, ale nevíte, jestli to bude pro vás? Neváhejte a zkuste to na zkoušku.",
    },
    {
      icon: <SyncIcon sx={{ fontSize: 80 }} />,
      title: "Výměna životů",
      text: "Uvažujete o komplexní změně, ale stěhování do jiného města je náročné rozhodnutí. Zkuste výměnu životů s kolegou na obdobné pozici.",
    },
  ]

  return (
    <Box component="section" py={10} bgcolor="secondary.main" ref={sectionRef}>
      <Container>
        <Typography component="h2" variant="h4" fontWeight={700} mb={4}>
          Co program nabízí?
        </Typography>
        <Grid container component="ul" spacing={4}>
          {values.map((value) => (
            <Grid item component="li" key={value.title} md={4}>
              <Stack component="article" spacing={2}>
                <Typography color="primary.main">{value.icon}</Typography>
                <Typography
                  component="h3"
                  variant="h5"
                  fontWeight={700}
                  color="primary.main"
                >
                  {value.title}
                </Typography>
                <Typography component="p" variant="body1" fontWeight={500}>
                  {value.text}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

function Offers() {
  return (
    <Box component="section" py={8} bgcolor="grey.200">
      <Container>
        <Typography component="h2" variant="h4" fontWeight={700} mb={4}>
          Doporučené nabídky
        </Typography>
        {/* <Stack direction="row" mb={2} spacing={2}>
          <Autocomplete
            options={[]}
            sx={{ width: 250 }}
            renderInput={(params) => (
              <TextField variant="filled" {...params} label="Země" />
            )}
          />
          <Autocomplete
            options={[]}
            sx={{ width: 250 }}
            renderInput={(params) => (
              <TextField variant="filled" {...params} label="Město" />
            )}
          />
          <Autocomplete
            options={[]}
            sx={{ width: 250 }}
            renderInput={(params) => (
              <TextField variant="filled" {...params} label="Kategorie" />
            )}
          />
        </Stack> */}
        <Paper variant="outlined" sx={{ overflow: "hidden" }}>
          <Stack divider={<Divider />}>
            {posts.map((demand, i) => (
              <ButtonBase
                component={NextLinkComposed}
                to="/login"
                sx={{ p: 3 }}
                key={i}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={1}>
                    <Avatar variant="rounded">#</Avatar>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Stack spacing={0.5}>
                      <Typography
                        component="h3"
                        variant="body1"
                        fontWeight={500}
                      >
                        {demand.title}
                      </Typography>
                      <Typography
                        component={Stack}
                        direction="row"
                        spacing={1}
                        divider={<InlineDivider />}
                        variant="body2"
                        color="text.secondary"
                      >
                        <Box>{demand.company}</Box>
                        <Box>{demand.location}</Box>
                        <Box>{demand.country}</Box>
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      component={Stack}
                      direction="row"
                      spacing={1}
                      divider={<InlineDivider />}
                      variant="body2"
                      color="text.secondary"
                    >
                      <Box>od 3. 8. 2021</Box>
                      <Box>2 měsíce</Box>
                      <Box>Full time</Box>
                    </Typography>
                  </Grid>
                </Grid>
              </ButtonBase>
            ))}
          </Stack>
        </Paper>
      </Container>
    </Box>
  )
}

function HowDoesItWork() {
  const steps = [
    "Registruj se do programu a vyplň si svůj profil.",
    "Aplikace určí vhodné kandidáty na základě profilu a filtrů.",
    "Sedíte si vzájemně? Gratulujeme. Dohodněte si detaily a zkuste změnu.",
  ]

  return (
    <Box component="section" py={8} bgcolor="grey.300">
      <Container>
        <Typography component="h2" variant="h4" fontWeight={700} mb={4}>
          Jak to funguje?
        </Typography>
        <Grid container component="ol" spacing={6}>
          {steps.map((step, index) => (
            <Grid item key={step} component="li" md={4}>
              <Stack spacing={2}>
                <Box
                  sx={{
                    width: "2em",
                    height: "2em",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "primary.contrastText",
                    bgcolor: "primary.main",
                    fontWeight: 700,
                    borderRadius: 1000,
                    fontSize: 26,
                  }}
                >
                  {index + 1}
                </Box>
                <Typography fontSize={20} fontWeight={700} color="primary.main">
                  {step}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default function Homepage() {
  const addedValuesRef = React.useRef<HTMLElement | null>(null)

  function handleHeroContinue() {
    addedValuesRef?.current?.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <HomepageLayout offsetHeader={false} heroHeader>
      <Hero onContinue={handleHeroContinue} />
      <AddedValues sectionRef={addedValuesRef} />
      <Offers />
      <HowDoesItWork />
      <EuBanner />
    </HomepageLayout>
  )
}
