import { useState } from "react"

export function useMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isOpen = Boolean(anchorEl)

  function handleOpen(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  if (process.browser) {
    window.addEventListener("resize", () => {
      handleClose()
    })
  }

  return { anchorEl, isOpen, handleOpen, handleClose }
}
