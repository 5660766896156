import React from "react"
import { Box } from "@mui/material"
import { SxProps, Theme } from "@mui/system"

export function InlineDivider({ sx }: { sx?: SxProps<Theme> }) {
  return (
    <Box component="span" sx={{ opacity: 0.5, ...sx }}>
      {"•"}
    </Box>
  )
}
