import { config } from "@/config"
import { SnackbarView } from "@/domain/snackbar"
import Head from "next/head"

/**
 * Generic layout wrapper component to set document title.
 * Every other layout file should be wrapped in this component.
 */
export function BaseLayout({
  children,
  title,
}: {
  children: React.ReactNode
  title?: string
}) {
  return (
    <>
      <Head>
        <title>{title ? `${title} • ${config.appName}` : config.appName}</title>
      </Head>
      {children}
      <SnackbarView />
    </>
  )
}
