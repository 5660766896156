import React from "react"
import {
  AppBar,
  Box,
  Button,
  ButtonBase,
  Container,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material"
import { NextLinkComposed } from "@/lib/routing"
import { useScroll } from "@/lib/hooks"
import { config } from "@/config"
import NextLink from "next/link"
import { InlineDivider } from "@/ui/inline-divider"
import { BaseLayout } from "@/views/_layout"
import { useSession } from "@/domain/auth"

const menuItems = [
  { label: "O projektu", href: "/" },
  { label: "Kontakt", href: "/contact" },
]

function HomepageAppBar({ hero }: { hero: boolean }) {
  const { scrollY } = useScroll()
  const isSolid = !hero || scrollY > (process.browser ? window.innerHeight : 0)
  const session = useSession()
  const isAuthenticated = session.type === "authenticated"

  return (
    <AppBar
      position="fixed"
      elevation={isSolid ? 2 : 0}
      sx={{
        bgcolor: isSolid ? "white" : "transparent",
        color: isSolid ? "text.primary" : "primary.contrastText",
        transition: (theme) =>
          theme.transitions.create(["background-color", "box-shadow"]),
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Stack direction="row" spacing={1}>
          <ButtonBase
            sx={{ borderRadius: 1, alignSelf: "strech", px: 1 }}
            component={NextLinkComposed}
            to="/"
          >
            {/* eslint-disable-next-line */}
            <img
              src={isSolid ? "logo-color.png" : "logo-white.png"}
              alt="Logo Life Experience Exchange"
              width={100}
            />
          </ButtonBase>
          {menuItems.map((item) => (
            <Button
              key={item.href}
              variant="text"
              component={NextLinkComposed}
              to={item.href}
              sx={{ color: isSolid ? "primary" : "white" }}
            >
              {item.label}
            </Button>
          ))}
        </Stack>
        <Stack direction="row" spacing={1}>
          {isSolid && (
            <Button
              color="primary"
              variant="text"
              component={NextLinkComposed}
              to="/signup"
            >
              Zaregistrovat se
            </Button>
          )}
          <Button
            color="primary"
            variant="contained"
            component={NextLinkComposed}
            to={isAuthenticated ? "/dashboard" : "/login"}
          >
            {isAuthenticated ? "Přejít do aplikace" : "Přihlásit se"}
          </Button>
        </Stack>
      </Toolbar>
    </AppBar>
  )
}

function Footer() {
  return (
    <Box
      component="footer"
      py={8}
      bgcolor="grey.900"
      color="white"
      sx={{
        a: {
          color: "white",
          textDecoration: "none",
          ":hover": { color: "grey.400" },
        },
      }}
    >
      <Container>
        <Stack spacing={2}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            spacing={4}
          >
            <Stack
              direction={{ xs: "column", md: "row" }}
              component="ul"
              spacing={2}
              divider={
                <InlineDivider sx={{ display: { xs: "none", md: "block" } }} />
              }
            >
              <li>
                <NextLink href="/contact">Pricing</NextLink>
              </li>
              <li>
                <NextLink href="/contact">Kontakt</NextLink>
              </li>
              <li>
                <NextLink href="/cookies">Cookies</NextLink>
              </li>
              <li>
                <NextLink href="/privacy-policy">
                  Ochrana osobních údajů
                </NextLink>
              </li>
              <li>
                <NextLink href="/contact">Obchodní podmínky</NextLink>
              </li>
              <li>
                <NextLink href="/eu-projects">EU projekty</NextLink>
              </li>
            </Stack>
          </Stack>
          <Typography variant="body2" color="grey.600">
            © {new Date().getFullYear()} {config.appName}
          </Typography>
        </Stack>
      </Container>
    </Box>
  )
}

export function HomepageLayout({
  children,
  title,
  offsetHeader = true,
  heroHeader = false,
}: {
  children: React.ReactNode
  title?: string
  offsetHeader?: boolean
  heroHeader?: boolean
}) {
  return (
    <BaseLayout title={title}>
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <HomepageAppBar hero={heroHeader} />
        <Box flexGrow={1} pt={offsetHeader ? 8 : 0}>
          {children}
        </Box>
        <Footer />
      </Box>
    </BaseLayout>
  )
}
