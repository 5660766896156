import { Post } from "@/domain/post"

export const posts: Array<Post> = [
  {
    id: "a",
    type: "demand",
    title: "JS frontend developer (TypeScript, Angular)",
    company: "Blacktag",
    category: "Developer",
    startsAt: new Date(2021, 7, 3),
    endsAt: new Date(2021, 9, 3),
    hoursPerWeek: 40,
    payPerHour: 300,
    location: "Praha",
    country: "Česká republika",
    description:
      "Meggings readymade art party beard waistcoat hashtag roof party chia. Poutine ethical biodiesel adaptogen before they sold out, banjo thundercats lo-fi. Next level portland brooklyn, vexillologist copper mug jianbing mustache yuccie try-hard irony. Plaid celiac selfies man braid 3 wolf moon. Squid four dollar toast affogato blog tacos, YOLO austin authentic hoodie cronut. Meggings street art meh raclette, bushwick intelligentsia unicorn man braid keytar retro vaporware. Shoreditch chillwave distillery letterpress tumblr, normcore snackwave craft beer offal air plant locavore af poke.",
  },
  {
    type: "demand",
    title: "JS frontend developer (TypeScript, Angular)",
    company: "Blacktag",
    category: "Developer",
    startsAt: new Date(2021, 7, 3),
    endsAt: new Date(2021, 9, 3),
    hoursPerWeek: 40,
    payPerHour: 300,
    location: "Praha",
    country: "Česká republika",
    description:
      "Meggings readymade art party beard waistcoat hashtag roof party chia. Poutine ethical biodiesel adaptogen before they sold out, banjo thundercats lo-fi. Next level portland brooklyn, vexillologist copper mug jianbing mustache yuccie try-hard irony. Plaid celiac selfies man braid 3 wolf moon. Squid four dollar toast affogato blog tacos, YOLO austin authentic hoodie cronut. Meggings street art meh raclette, bushwick intelligentsia unicorn man braid keytar retro vaporware. Shoreditch chillwave distillery letterpress tumblr, normcore snackwave craft beer offal air plant locavore af poke.",
  },
  {
    type: "demand",
    title: "ASP .NET vývojář",
    company: "Blacktag",
    category: "Developer",
    startsAt: new Date(2022, 7, 3),
    endsAt: new Date(2022, 9, 3),
    hoursPerWeek: 40,
    payPerHour: 300,
    location: "Praha",
    country: "Česká republika",
    description:
      "Meggings readymade art party beard waistcoat hashtag roof party chia. Poutine ethical biodiesel adaptogen before they sold out, banjo thundercats lo-fi. Next level portland brooklyn, vexillologist copper mug jianbing mustache yuccie try-hard irony. Plaid celiac selfies man braid 3 wolf moon. Squid four dollar toast affogato blog tacos, YOLO austin authentic hoodie cronut. Meggings street art meh raclette, bushwick intelligentsia unicorn man braid keytar retro vaporware. Shoreditch chillwave distillery letterpress tumblr, normcore snackwave craft beer offal air plant locavore af poke.",
  },
  {
    type: "demand",
    title: "Dynamic Optimization Specialist",
    company: "Blacktag",
    category: "Developer",
    startsAt: new Date(2020, 7, 3),
    endsAt: new Date(2020, 9, 3),
    hoursPerWeek: 40,
    payPerHour: 300,
    location: "Praha",
    country: "Česká republika",
    description:
      "Meggings readymade art party beard waistcoat hashtag roof party chia. Poutine ethical biodiesel adaptogen before they sold out, banjo thundercats lo-fi. Next level portland brooklyn, vexillologist copper mug jianbing mustache yuccie try-hard irony. Plaid celiac selfies man braid 3 wolf moon. Squid four dollar toast affogato blog tacos, YOLO austin authentic hoodie cronut. Meggings street art meh raclette, bushwick intelligentsia unicorn man braid keytar retro vaporware. Shoreditch chillwave distillery letterpress tumblr, normcore snackwave craft beer offal air plant locavore af poke.",
  },
  {
    type: "demand",
    title: "Dynamic Optimization Specialist",
    company: "Blacktag",
    category: "Developer",
    startsAt: new Date(2020, 7, 3),
    endsAt: new Date(2020, 9, 3),
    cancelledAt: new Date(),
    hoursPerWeek: 40,
    payPerHour: 300,
    location: "Praha",
    country: "Česká republika",
    description:
      "Meggings readymade art party beard waistcoat hashtag roof party chia. Poutine ethical biodiesel adaptogen before they sold out, banjo thundercats lo-fi. Next level portland brooklyn, vexillologist copper mug jianbing mustache yuccie try-hard irony. Plaid celiac selfies man braid 3 wolf moon. Squid four dollar toast affogato blog tacos, YOLO austin authentic hoodie cronut. Meggings street art meh raclette, bushwick intelligentsia unicorn man braid keytar retro vaporware. Shoreditch chillwave distillery letterpress tumblr, normcore snackwave craft beer offal air plant locavore af poke.",
  },
  {
    type: "demand",
    title: "Regional implementation administrator",
    company: "Acme corporation",
    category: "Executive",
    startsAt: new Date(2021, 7, 3),
    endsAt: new Date(2021, 9, 3),
    hoursPerWeek: 40,
    payPerHour: 300,
    location: "Helsinki",
    country: "Finsko",
    description:
      "Meggings readymade art party beard waistcoat hashtag roof party chia. Poutine ethical biodiesel adaptogen before they sold out, banjo thundercats lo-fi. Next level portland brooklyn, vexillologist copper mug jianbing mustache yuccie try-hard irony. Plaid celiac selfies man braid 3 wolf moon. Squid four dollar toast affogato blog tacos, YOLO austin authentic hoodie cronut. Meggings street art meh raclette, bushwick intelligentsia unicorn man braid keytar retro vaporware. Shoreditch chillwave distillery letterpress tumblr, normcore snackwave craft beer offal air plant locavore af poke.",
  },
  {
    type: "demand",
    title: "Internal usability orchestrator",
    company: "Google",
    category: "Developer",
    startsAt: new Date(2021, 7, 3),
    endsAt: new Date(2021, 9, 3),
    hoursPerWeek: 40,
    payPerHour: 300,
    location: "Praha",
    country: "Česká republika",
    description:
      "Meggings readymade art party beard waistcoat hashtag roof party chia. Poutine ethical biodiesel adaptogen before they sold out, banjo thundercats lo-fi. Next level portland brooklyn, vexillologist copper mug jianbing mustache yuccie try-hard irony. Plaid celiac selfies man braid 3 wolf moon. Squid four dollar toast affogato blog tacos, YOLO austin authentic hoodie cronut. Meggings street art meh raclette, bushwick intelligentsia unicorn man braid keytar retro vaporware. Shoreditch chillwave distillery letterpress tumblr, normcore snackwave craft beer offal air plant locavore af poke.",
  },
  {
    type: "demand",
    title: "Senior response consultant",
    company: "Initech",
    category: "Developer",
    startsAt: new Date(2021, 7, 3),
    endsAt: new Date(2021, 9, 3),
    hoursPerWeek: 40,
    payPerHour: 300,
    location: "Ostrava",
    country: "Česká republika",
    description:
      "Meggings readymade art party beard waistcoat hashtag roof party chia. Poutine ethical biodiesel adaptogen before they sold out, banjo thundercats lo-fi. Next level portland brooklyn, vexillologist copper mug jianbing mustache yuccie try-hard irony. Plaid celiac selfies man braid 3 wolf moon. Squid four dollar toast affogato blog tacos, YOLO austin authentic hoodie cronut. Meggings street art meh raclette, bushwick intelligentsia unicorn man braid keytar retro vaporware. Shoreditch chillwave distillery letterpress tumblr, normcore snackwave craft beer offal air plant locavore af poke.",
  },
  {
    type: "demand",
    title: "Dynamic security executive",
    company: "E Corp",
    category: "Developer",
    startsAt: new Date(2021, 7, 3),
    endsAt: new Date(2021, 9, 3),
    hoursPerWeek: 40,
    payPerHour: 300,
    location: "Lima",
    country: "Peru",
    description:
      "Meggings readymade art party beard waistcoat hashtag roof party chia. Poutine ethical biodiesel adaptogen before they sold out, banjo thundercats lo-fi. Next level portland brooklyn, vexillologist copper mug jianbing mustache yuccie try-hard irony. Plaid celiac selfies man braid 3 wolf moon. Squid four dollar toast affogato blog tacos, YOLO austin authentic hoodie cronut. Meggings street art meh raclette, bushwick intelligentsia unicorn man braid keytar retro vaporware. Shoreditch chillwave distillery letterpress tumblr, normcore snackwave craft beer offal air plant locavore af poke.",
  },
  {
    type: "demand",
    title: "C#/.NET Developer (MS Azure)",
    company: "YBM inc.",
    category: "Developer",
    startsAt: new Date(2021, 7, 3),
    endsAt: new Date(2021, 9, 3),
    hoursPerWeek: 40,
    payPerHour: 300,
    location: "Magnitogorsk",
    country: "Rusko",
    description:
      "Meggings readymade art party beard waistcoat hashtag roof party chia. Poutine ethical biodiesel adaptogen before they sold out, banjo thundercats lo-fi. Next level portland brooklyn, vexillologist copper mug jianbing mustache yuccie try-hard irony. Plaid celiac selfies man braid 3 wolf moon. Squid four dollar toast affogato blog tacos, YOLO austin authentic hoodie cronut. Meggings street art meh raclette, bushwick intelligentsia unicorn man braid keytar retro vaporware. Shoreditch chillwave distillery letterpress tumblr, normcore snackwave craft beer offal air plant locavore af poke.",
  },
].map((post, index) => ({ ...post, id: String(index) } as Post))
