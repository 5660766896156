import { useState } from "react"

interface PaginationConfig {
  itemsPerPage: number
}

interface UsePaginationResult<T> {
  pageNumber: number
  pageCount: number
  pageItems: Array<T>
  handlePageChange: (n: number) => void
}

export function usePagination<T>(
  items: Array<T>,
  { itemsPerPage }: PaginationConfig
): UsePaginationResult<T> {
  const [pageNumber, setPageNumber] = useState(1)
  const pageCount = Math.ceil(items.length / itemsPerPage)
  const pageIndex = pageNumber - 1

  const pageItems = items.slice(
    pageIndex * itemsPerPage,
    (pageIndex + 1) * itemsPerPage
  )

  return {
    pageNumber,
    pageCount,
    pageItems,
    handlePageChange: (number) => setPageNumber(number),
  }
}
